import * as _callBindApplyHelpers2 from "call-bind-apply-helpers";
var _callBindApplyHelpers = _callBindApplyHelpers2;
try {
  if ("default" in _callBindApplyHelpers2) _callBindApplyHelpers = _callBindApplyHelpers2.default;
} catch (e) {}
import * as _gopd2 from "gopd";
var _gopd = _gopd2;
try {
  if ("default" in _gopd2) _gopd = _gopd2.default;
} catch (e) {}
var exports = {};
var callBind = _callBindApplyHelpers;
var gOPD = _gopd;

// eslint-disable-next-line no-extra-parens, no-proto
var hasProtoAccessor = /** @type {{ __proto__?: typeof Array.prototype }} */[].__proto__ === Array.prototype;

// eslint-disable-next-line no-extra-parens
var desc = hasProtoAccessor && gOPD && gOPD(Object.prototype, /** @type {keyof typeof Object.prototype} */"__proto__");
var $Object = Object;
var $getPrototypeOf = $Object.getPrototypeOf;

/** @type {import('./get')} */
exports = desc && typeof desc.get === "function" ? callBind([desc.get]) : typeof $getPrototypeOf === "function" ? /** @type {import('./get')} */function getDunder(value) {
  // eslint-disable-next-line eqeqeq
  return $getPrototypeOf(value == null ? value : $Object(value));
} : false;
export default exports;